var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-2" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1 mt-3",
            attrs: {
              dense: "",
              items: _vm.items,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id))]
                },
              },
              {
                key: "item.nickname",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.user.nickname))]
                },
              },
              {
                key: "item.currency_amount",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(item.currency_amount) +
                        " " +
                        _vm._s(item.currency.short_title) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.payment_link",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "blue",
                        attrs: {
                          dense: "",
                          text: "",
                          target: "_blank",
                          href: item.payment_link,
                        },
                      },
                      [_vm._v("ссылка ")]
                    ),
                  ]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [_c("StatusCell", { attrs: { item: item } })]
                },
              },
              {
                key: "item.created_at",
                fn: function ({ item }) {
                  return [
                    _c("DateTimeCell", { attrs: { value: item.created_at } }),
                  ]
                },
              },
              {
                key: "item.updated_at",
                fn: function ({ item }) {
                  return [
                    _c("DateTimeCell", { attrs: { value: item.updated_at } }),
                  ]
                },
              },
              {
                key: "item.hired_at",
                fn: function ({ item }) {
                  return [
                    item.hired_at
                      ? _c("DateTimeCell", { attrs: { value: item.hired_at } })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.proof",
                fn: function ({ item }) {
                  return [
                    item.proof
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "blue",
                            attrs: {
                              dense: "",
                              text: "",
                              target: "_blank",
                              href: item.proof,
                            },
                          },
                          [_vm._v("чек ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.time",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          ` ${_vm.formatPeriod(
                            item.created_at,
                            item.updated_at
                          )}`
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    item.status === "В ожидании"
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "green mr-1 mb-1",
                            attrs: { dense: "", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.take(item)
                              },
                            },
                          },
                          [_vm._v("В раб ")]
                        )
                      : _vm._e(),
                    item.status === "В ожидании"
                      ? _c("TextInputDialog", {
                          attrs: {
                            buttonclass: "red",
                            buttontext: "Отмена",
                            caption: "Причина",
                            tag: item,
                          },
                          on: { ok: _vm.del },
                        })
                      : _vm._e(),
                    item.status === "В процессе"
                      ? _c("ImagePickerDialog", {
                          attrs: { caption: "Успех", tag: item },
                          on: { ok: _vm.good },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Transactions ma-2" }, [
      _c("h3", [_vm._v("Оплаты - архив")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }